// based on rc-menu 7.4.21
import Menu from './Menu';
import SubMenu from './SubMenu';
import MenuItem, { menuItemProps } from './MenuItem';
import MenuItemGroup from './MenuItemGroup';
import Divider from './Divider';

export {
  SubMenu,
  MenuItem as Item,
  menuItemProps as itemProps,
  MenuItem,
  MenuItemGroup,
  MenuItemGroup as ItemGroup,
  Divider,
};

export default Menu;
